import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDiscoverPosts, DISCOVER_TYPES } from '../../redux/actions/discoverAction'
import LoadIcon from '../../images/loading.gif'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import { getDataAPI } from '../../utils/fetchData'
import Carousel from '../publicidad/Carousel'
import Send from '../../images/send.svg'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Avatar from '../Avatar'
import { GlobalState } from '../../GlobalState'
import { TikTok } from 'react-tiktok'


const RecomendacionPost = () => {
    const { auth, discover, theme } = useSelector(state => state)
    const dispatch = useDispatch()

    const [load, setLoad] = useState(false)
    const [readMore, setReadMore] = useState(false)

    //tipo de usuario
    const state = useContext(GlobalState)
    const [linkstiktok] = state?.postAPI?.linktiktok || []
    //

    useEffect(() => {
        if (!discover.firstLoad) {
            dispatch(getDiscoverPosts(auth.token))
        }
    }, [dispatch, auth.token, discover.firstLoad])

    const handleLoadMore = async () => {
        setLoad(true)
        const res = await getDataAPI(`post_discover?num=${discover.page * 12}`, auth.token)
        dispatch({ type: DISCOVER_TYPES.UPDATE_POST, payload: res.data })
        setLoad(false)
    }

    // Función para intercalar 6 publicaciones de `discover` y 1 enlace de `linkstiktok`
const intercalatePosts = (discoverPosts = [], tiktokLinks = []) => {
    const intercalated = [];
    let discoverIndex = 0;
    let tiktokIndex = 0;

    // Recorremos ambos arreglos hasta que se hayan procesado todos los elementos
    while (discoverIndex < discoverPosts.length || tiktokIndex < tiktokLinks.length) {
        // Añadir hasta 6 publicaciones de discover si quedan elementos
        for (let i = 0; i < 3 && discoverIndex < discoverPosts.length; i++) {
            intercalated.push({ type: 'discover', data: discoverPosts[discoverIndex++] });
        }

        // Añadir 1 enlace de TikTok si queda alguno disponible
        if (tiktokIndex < tiktokLinks.length) {
            intercalated.push({ type: 'tiktok', data: tiktokLinks[tiktokIndex++] });
        }
    }
    
    return intercalated;
};

// Generar la lista intercalada de publicaciones
const intercalatedPosts = intercalatePosts(discover.posts || [], linkstiktok || []);


    console.log(linkstiktok)

    return (
        <>
            <h1 className='title_recomend_user'>Post recomendados</h1>
            <div className="post-container">
                {intercalatedPosts.map((item, index) => (
                    item.type === 'discover' ? (
                        // Renderizar publicación de discover
                        <div key={item.data._id || index} className="post">
                            <div className='content_cardN'>
                                <div className='card_header recomend'>
                                    <div className="d-flex">
                                        {item.data.user ? (
                                            <>
                                                <Avatar src={item.data.userDetails.avatar} size="big-avatar" />
                                                <div className="card_name recomend">
                                                    <Link to={`/post/${item.data._id}`} >
                                                        <h6 className="m-0">{item.data.userDetails.username}</h6>
                                                    </Link>
                                                    <small className="text-muted">
                                                        {moment(item.data.createdAt).fromNow()}
                                                    </small>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="card_name">
                                                <h6 className="m-0">Usuario Desconocido</h6>
                                                <small className="text-muted">Fecha Desconocida</small>
                                            </div>
                                        )}
                                    </div>
                                    <div className="nav-item dropdown" style={{ display: 'block' }}>
                                        <span className="material-icons" id="moreLink" data-toggle="dropdown">menu</span>
                                        <div className="dropdown-menu">
                                            <div className="dropdown-item">
                                                <Link to={`/post/${item.data._id}`}>
                                                    <span className="material-icons">delete_outline</span> Ver Post
                                                </Link>
                                            </div>
                                            <div className="dropdown-item">
                                                <span className="material-icons">content_copy</span> Copiar Link
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='card_bodyN'>
                                    <div className="card_body-content recomend">
                                        <span>
                                            {item.data.content.length < 60
                                                ? item.data.content
                                                : readMore ? item.data.content + ' ' : item.data.content.slice(0, 60) + '.....'
                                            }
                                        </span>
                                        {item.data.content.length > 60 && (
                                            <span onClick={() => setReadMore(!readMore)} className="readMore">
                                                {readMore ? 'Ocultar' : 'Lee más'}
                                            </span>
                                        )}
                                    </div>
                                    {item.data.images.length > 0 && (
                                        <div className='content-carousel-img'>
                                            <Carousel images={item.data.images} id={item.data._id} />
                                        </div>
                                    )}
                                </div>

                                <div className='card_footer recomend'>
                                    <div className="card_icon_menu recomend">
                                        <Link to={`/post/${item.data._id}`}>
                                            <i className="fa-regular fa-heart"></i>
                                        </Link>
                                        <Link to={`/post/${item.data._id}`}>
                                            <i className="far fa-comment" />
                                        </Link>
                                        <img src={Send} alt="Send" />
                                        <i className="far fa-bookmark" />
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <Link to={`/post/${item.data._id}`}>
                                            <h6 style={{ padding: '0 25px', cursor: 'pointer' }}>
                                                {item.data.likes.length} likes
                                            </h6>
                                        </Link>
                                        <Link to={`/post/${item.data._id}`}>
                                            <h6 style={{ padding: '0 25px', cursor: 'pointer' }}>
                                                {item.data.comments.length} comentarios
                                            </h6>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // Renderizar enlace de TikTok
                        <div className="content_linktiktok" key={item.data.urlcont || index}>
                            <TikTok url={item.data.urlcont} />
                        </div>
                    )
                ))}
            </div>
            {load && <img src={LoadIcon} alt="loading" />}
            <LoadMoreBtn result={discover.result} page={discover.page} handleLoadMore={handleLoadMore} load={load} />
        </>
    );
}

export default RecomendacionPost;
